<template>
    <div class="vx-col md:w-full sm:w-auto">
        <div class="vx-row md:w-full">
            <vx-tooltip class="position-icon" text="Chỉnh sửa" position="top">
                <feather-icon icon="EditIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                              @click="editRecord"/>
            </vx-tooltip>

            <vx-tooltip class="position-icon" text="Xóa" position="top">
                <feather-icon icon="TrashIcon" class="action-err icon-btn"
                              svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                              @click="confirmDeleteRecord"/>
            </vx-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {

        editRecord() {
            let branchId = this.params.data.organizationBranchId;
            this.$router.push(`/user/edit-wifi?id=${this.params.data.id}${branchId ? `&branch_id=${branchId}` : ''}`).catch(() => {
            })
        },
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xoá wifi "${this.params.data.wifiName}" không ?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },

        deleteRecord() {
            this.$vs.loading();
            this.$crm.post(`/wifi/delete/${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                return this.showDeleteSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        showDeleteSuccess() {
            this.$store.commit('DELETE_WIFI_STATUS', true);
            this.$vs.notify({
                color: 'success',
                text: 'Xoá wifi thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>
<style scoped>


</style>

